<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <AError :api="this.api"/>
        <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">

        </v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="()=>{
                        $router.go(-1);
                    }"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar class="secondary white--text text-h6">
                        Credit Card
                    </v-toolbar>
                    <v-form>
                        <div class="d-flex flex-column mt-4">
                            <div class="flex">
                                <v-text-field v-model="cardBrand" dense outlined filled disabled label="Brand">
    
                                </v-text-field>
                                <v-text-field v-model="lastFourNumber" dense outlined label="4 last digit" filled disabled>
                                </v-text-field>
                            </div>
                            <div class="d-flex justify-end">
                                <v-btn color="primary"  plain @click="()=>{
                                    $router.go(-1)
                                }">
                                    Cancel
                                </v-btn> 
                                <v-btn @click="validateInput" color="primary" v-if="this.lastFourNumber!=null">
                                    Delete
                                </v-btn>
                                <v-btn disabled color="primary" v-else>
                                    Delete
                                </v-btn>
                            </div>
                        </div>
                    </v-form>

                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        lastFourNumber:null,
        cardBrand:null,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {

            if(resp.data.status=="fail"){
                this.api.isError = true;
                this.api.error = resp.data.message;
                this.api.isLoading = false;
                return 1;
            }
            if(resp.class=="getPaymentDetail"){
                this.lastFourNumber = resp.data.card_last_four;
                this.cardBrand = resp.data.card_brand;

            }
            if(resp.class=="removePaymentDetail"){
                this.api.isSuccesful = true;
                this.api.success = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompanyCreditCardInformationApi = this.fetchCompanyCreditCardInformation();
            this.$api.fetch(fetchCompanyCreditCardInformationApi);
        },
        fetchCompanyCreditCardInformation(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/payment/credit_card/company/"+this.$router.history.current.params.id;
          
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let removeCreditCardApi = this.removeCreditCard();
            this.$api.fetch(removeCreditCardApi);
        },
        removeCreditCard(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/payment/credit_card/company/"+this.$router.history.current.params.id;
            tempApi.params = {
                "userId":this.$store.getters.getUserId,
            }
            return tempApi;
        },  
        cancelSubmit(){
            this.isPending = true;
        },
        create(){

        },
    }
}
</script>